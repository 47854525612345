exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booksy-js": () => import("./../../../src/pages/booksy.js" /* webpackChunkName: "component---src-pages-booksy-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-depilacao-js": () => import("./../../../src/pages/depilacao.js" /* webpackChunkName: "component---src-pages-depilacao-js" */),
  "component---src-pages-design-de-sobrancelha-js": () => import("./../../../src/pages/design-de-sobrancelha.js" /* webpackChunkName: "component---src-pages-design-de-sobrancelha-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maquiagem-js": () => import("./../../../src/pages/maquiagem.js" /* webpackChunkName: "component---src-pages-maquiagem-js" */),
  "component---src-pages-massagem-relaxante-nos-pes-js": () => import("./../../../src/pages/massagem-relaxante-nos-pes.js" /* webpackChunkName: "component---src-pages-massagem-relaxante-nos-pes-js" */),
  "component---src-pages-micropigmentacao-js": () => import("./../../../src/pages/micropigmentacao.js" /* webpackChunkName: "component---src-pages-micropigmentacao-js" */),
  "component---src-pages-micropuntura-js": () => import("./../../../src/pages/micropuntura.js" /* webpackChunkName: "component---src-pages-micropuntura-js" */),
  "component---src-pages-podologia-js": () => import("./../../../src/pages/podologia.js" /* webpackChunkName: "component---src-pages-podologia-js" */),
  "component---src-pages-tratamentos-faciais-js": () => import("./../../../src/pages/tratamentos-faciais.js" /* webpackChunkName: "component---src-pages-tratamentos-faciais-js" */)
}

